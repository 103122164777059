/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import axios from 'axios';

import theme from '../theme';
import { Badge } from '../Application/Badge';

type HeaderWrapProps = {
  withTabs?: boolean;
  className?: string;
}

const HeaderWrap = styled.div<HeaderWrapProps>`
  background: #FFFFFF;
  position: relative;
  box-shadow: 6px 6px 24px rgba(0, 0, 0, 0.06);
  padding: ${({ withTabs }): string => (withTabs ? '16px 0 0 0' : '16px 0')};
  height: ${({ withTabs }): string => (withTabs ? '111px' : '')};
  img {
    display:block;
    margin-left:${theme.spacing.s};
    margin-bottom: ${({ withTabs }): string => (withTabs ? `${theme.spacingValues.xxxs}px` : '0')};
    width: 103px;
    height:24px;
  }
`;

const StyledLinks = styled.div<HeaderWrapProps>`
  display: inline-block;
  padding-left: ${theme.spacing.s};
  padding-top: 20px;
  &.sticky {
    position: fixed;
    background: white;
    overflow: hidden;
    z-index: 1000;
    width: 100%;
    top: 0;
    box-shadow: 6px 6px 24px rgba(0,0,0,0.06);
  }
  a {
    display: inline-block;
    width: 104px;
    margin-right: ${theme.spacingValues.xs}px;
    padding: ${theme.spacing.xxs} 0 ${theme.spacingValues.xxxs}px 0;
    text-align: center;
    transition: border-bottom-color 0.5s;
    color: ${theme.colors.greyDark60};
    border-bottom: '3px solid white';
    cursor: pointer;
    text-decoration: none;
  }
  a[aria-current='page']{
    font-weight: 600;
    color: ${theme.colors.legalAidRed};
    border-bottom: ${`3px solid ${theme.colors.legalAidRed}`};
    cursor: default;
  }
  @media (max-width: 375px) {
    padding-left: ${theme.spacingValues.xs}px;
    a {
      width: 98px;
    }
  }
  @media (max-width: 320px) {
    padding: 0 ${theme.spacingValues.xxxs}px;
    a {
      width: 95px;
      margin-right: ${theme.spacingValues.vs}px;
    }
  }
`;

const UpdatesTabWrap = styled.div`
  display: inline;
  width: 110px;
  position: relative;

  @media (max-width: 375px) {
      width: 95px;
  }
`;

interface HeaderProps {
  withLogo?: boolean;
  withTabs?: boolean;
  activeTab?: string;
}

const Header: React.FC<HeaderProps> = ({
  withTabs = false, withLogo = true, activeTab = 'updates', children = null,
}) => {
  const [badgeNumber, setBadgeNumber] = useState(0);
  const [shouldStick, setShouldStick] = useState(false);
    
  useEffect(() => {
    if(withTabs && !!window.IntersectionObserver) {
      const observer = new IntersectionObserver(([target]) => {
        if (target.isIntersecting) {
          setShouldStick(false)
        } else {
          setShouldStick(true)
        }
      }, {rootMargin: '27px', threshold: 1.00});

      const logo = document.querySelector("#logo");

      if (logo !== null && logo !== undefined) {
        observer.observe(logo);
      }

      return (): void => {
        if (logo !== null) {
          observer.unobserve(logo);
        }
      }
    } else {
      return undefined;
    }
  }, []);

  useEffect(() => {
    if (withTabs) {
      const getUpdatesNumber = async(): Promise<void> => {
        try {
          const result = await axios
            .create({ withCredentials: true })
            .get(`${process.env.api}/users/updates-number`);
          setBadgeNumber(result.data);
        } catch(e) {}
      }
      getUpdatesNumber();
    }
  },[])

  return (
    <HeaderWrap withTabs={withTabs}>
    { withLogo && <img src="/static/logo.png" alt="Legal Aid NSW" id="logo" /> }
    { children }
    { withTabs && (
      <StyledLinks className={shouldStick ? "sticky" : ""}>
        <Link href="/updates" passHref>
          <UpdatesTabWrap>
            <a aria-current={activeTab === 'updates' ? 'page' : 'false'} href="/updates">
              Updates
              {badgeNumber > 0 ? <Badge numberOfUpdates={badgeNumber} /> : null}
            </a>
          </UpdatesTabWrap>
        </Link>
        <Link href="/applications" passHref>
          <a aria-current={activeTab === 'applications' ? 'page' : 'false'} href="/applications">Applications</a>
        </Link>
        <Link href="/learn-more" passHref>
          <a aria-current={activeTab === 'learn-more' ? 'page' : 'false'} href="/learn-more">Learn more</a>
        </Link>
      </StyledLinks>
    )}
  </HeaderWrap>
  )
}

export default Header;
