/* eslint-disable import/extensions */
import React from 'react';
import styled from 'styled-components';
import theme from '../theme';
import { Paragraph } from '../Typography/Typography';

type BadgeProps = {
  numberOfUpdates: number;
}

const StyledBadge = styled.span<BadgeProps>`
  background-color: ${theme.colors.legalAidRed};
  border: none;
  border-radius: 50%;
  position: absolute;
  height: 16px;
  width: 16px;
  float: right;
  top: -8px;
  right: 16px;
  @media (max-width: 320px) {
    right: 4px;
  }
`;

export const Badge: React.FC<BadgeProps> = ({ numberOfUpdates }) => {
  return (
    <>
    {numberOfUpdates > 0 ?
      <StyledBadge numberOfUpdates={numberOfUpdates} >
        <Paragraph
          fontSize="10px"
          optionalColor={theme.colors.white}
          bold
          center
          data-cy='badge'
        >
          {numberOfUpdates}
        </Paragraph>
      </StyledBadge>
    : null}
  </>
)};
