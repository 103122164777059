export default {
  colors: {
    white: '#FFFFFF',
    black: '#000000',

    highlightBlue: '#07607C',
    clickableBlue: '#002664',  // aka primary blue
    darkBlue: '#001D4E',
    secondaryBlue: '#2E5299',  // aka notifications/info blue
    lightBlue: '#D9E0ED',

    legalAidRed: '#9D1C46',
    errorRedBackground: 'rgba(184, 18, 55, 0.1)',
    red: '#740C2C',

    warningOrange: '#DC5800',
    lightOrange: '#F8DECC',

    gold: '#D18A46',
    lightYellow: '#F4EACA',

    green: '#098C5D',
    lightGreen: '#D0E6DE',

    disabledGreyBackground: '#E4E4E6',
    disabledTextGrey: '#A0A5AE',
    darkGrey: '#4C4F55',
    lightGrey: '#E0E4E5',
    lighterGrey: '#F4F4F7',
    backgroundGrey: '#F8F8FA',
    greyDark80: '#333333',
    greyDark70: '#4C4F55',
    greyDark60: '#6D7079'
  },
  spacing: {
    xxs: '12px',
    xs: '16px',
    s: '24px',
    m: '32px',
    l: '40px',
    mxl: '48px',
    xl: '80px',
  },
  spacingValues: {
    vs: 4,
    xxxs: 8,
    xxs: 12,
    xs: 16,
    s: 24,
    m: 32,
    l: 48,
    xl: 64,
    xxl: 80,
    page: 300,
  },
  fontSize: {
    xxxs: '8px',
    xxs: '12px',
    xs: '14px',
    xsx: '16px',
    s: '18px',
    m: '20px',
    l: '24px',
  },
};
