/* eslint-disable import/extensions */
import styled from 'styled-components';

import theme from '../theme';

type TypographyProps = {
  optionalColor?: string;
  bold?: boolean;
  marginBottom?: number;
  marginTop?: number;
  light?: boolean;
  paddingLeft?: number;
  inline?: boolean;
  center?: boolean;
  fontSize?: string;
  shouldDisplay?: boolean;
  'data-cy'?: string;
  className?: string;
}

export const HeadingMain = styled.h1<TypographyProps>`
  font-size: ${({ fontSize }): string => (fontSize || theme.fontSize.l)};
  margin-bottom: ${({ marginBottom }): number => (marginBottom || theme.spacingValues.xxs)}px;
  margin-top: ${({ marginTop }): number => (marginTop || 0)}px;
  display: block;
  line-height: 125%;
  text-align: ${({ center }): string => (center ? 'center' : 'left')};
`;

export const HeadingMainSmall = styled.h1<TypographyProps>`
  font-size: ${theme.fontSize.m};
  margin-bottom: ${({ marginBottom }): number => (marginBottom || theme.spacingValues.xxs)}px;
  line-height: 125%;
`;

export const HeadingMedium = styled.h2<TypographyProps>`
  font-size: ${({ fontSize }): string => fontSize ? fontSize : theme.fontSize.s};
  margin= 0;
  margin-bottom: ${({ marginBottom }): number => (marginBottom || 0)}px;
  margin-top: ${({ marginTop }): number => (marginTop || 0)}px;
  font-weight: ${({ light }): number => (light ? 400 : 600)};
  line-height: 125%;
`;

export const Paragraph = styled.p<TypographyProps>`
  font-size: ${({ fontSize }): string => fontSize ? fontSize : theme.fontSize.xs};
  margin: 0;
  color: ${({ optionalColor }): string => (optionalColor || 'black')};
  font-weight: ${({ bold, light }): number => {
    if (bold) { return 600 }
    if (light) { return 300 } 
    return 400;
    }};
  margin-bottom: ${({ marginBottom }): number => (marginBottom || 0)}px;
  margin-top: ${({ marginTop }): number => (marginTop || 0)}px;
  display: ${({ inline, shouldDisplay }): string => {
    if (shouldDisplay === false) {
      return 'none'
    }
    if (inline) {
      return 'inline'
    }
    return 'block'
  }};
  text-align: ${({ center }): string => (center ? 'center' : 'left')};
  padding-left: ${({ paddingLeft }): number => (paddingLeft || 0)}px;
`;
