import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Head from 'next/head';

import theme from '../theme';
import Header from '../Header/Header';

type GlobalStyleProps = {
  bgcolor?: string;
}

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html {
    box-sizing: border-box;
    font-size: 14px;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  *, body {
    font-family: 'Montserrat', sans-serif;
    strong {
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
    }
  }
  body {
    background-color: ${({bgcolor}): string => (bgcolor || theme.colors.white)};
    margin:0;
    padding:0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat';
    font-weight: 600;
  }
  h1{
    margin-top:0;
    font-size: 1.857rem;
    line-height: 2.142rem;
    letter-spacing: -0.02em;
  }
  h2{
    margin-top:0;
    font-size: 1.357rem;
    line-height: 1.571rem;
    letter-spacing: -0.02em;
  }
  h3{
    font-size: 1.142rem;
    line-height: 1.428rem;
  }
  p, ul li{
    font-size: 1rem;
    line-height: 1.357rem;
  }
  a {
    cursor: pointer;
  }
`;

type ContentWrapperProps = {
  withContentPadding?: boolean;
}

const ContentWrapper = styled.div<ContentWrapperProps>`
  padding: ${({ withContentPadding = true }): string => (withContentPadding ? theme.spacing.s : '0')};
`;

interface PageContainerProps {
  headerChildren?: React.ReactElement<any> | undefined;
  withHeader?: boolean;
  withLogo?: boolean;
  withTabs?: boolean;
  activeTab?: string;
  withContentPadding?: boolean;
  bgcolor?: string;
  numberOfUpdates?: number;
}

const PageContainer: React.FC<PageContainerProps> = ({
  children, headerChildren, withHeader = true, bgcolor, ...props
}) => (
  <>
    <Head>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap" rel="stylesheet"/>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <title>Legal Aid NSW | Grants Tracker</title>
    </Head>
    <GlobalStyle bgcolor={bgcolor} />
    { withHeader && <Header {...props}>{headerChildren}</Header> }
    <ContentWrapper {...props}>{children}</ContentWrapper>
  </>
);

export default PageContainer;
